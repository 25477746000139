import * as React from 'react'
import { useDispatch } from 'react-redux'

import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { UserProfileAccount, UserProfileServiceAddress } from '@igs-web/common-models/models/user-profile-model'
import { InfoText } from '@igs-web/common-ui-components/_molecules/info-text'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { getBillingAccountServiceAddresses } from '@igs-web/common-utilities/utilities/billing-account-utilities'

import { MostRecentInvoiceLink } from '../billing/most-recent-invoice-link'
import { Verbiage } from '../company/companyVerbiage'
import { useCompany } from '../company/hooks/useCompany'
import { useMyAccountDashboard } from '../myaccount/dashboard-context'
import { userActions } from '../user/user-redux'

import { BalanceDueDetailsBillingAccount } from './balance-due-details-billing-account'
import { BalanceDueDetailsPartyAccount } from './balance-due-details-party-account'
import { PaymentPortalForm } from './payment-portal-form'

export const BalanceDueDetailSection = ({ accountId, showPayBillLink }: BalanceDueDetailSectionProps) => {
    const { features, verbiage } = useCompany()
    const dispatch = useDispatch()

    const {
        billingAccount: selectedBillingAccount,
        profile,
        getBillingAccountForPartyAccount,
        showPaymentDelayMessage,
        getBillingAccountStatus,
    } = useMyAccountDashboard()

    const billingAccount = selectedBillingAccount && features.useBillingAccounts ? selectedBillingAccount : getBillingAccountForPartyAccount(accountId)
    const { billingAccountNumber } = billingAccount || {}
    const isActive =
        selectedBillingAccount && features.useBillingAccounts
            ? getBillingAccountStatus(selectedBillingAccount.billingAccountNumber) === AccountStatus.active
            : profile.accounts.find(a => a.accountId === accountId)?.status === AccountStatus.active

    React.useEffect(() => {
        const getPaymentPlan = async () => {
            if (billingAccount && billingAccount.isOnPaymentPlan === undefined && !profile.isCommercialIndustrial) {
                const isOnPaymentPlan = await customerInfoApiClient.getIsOnPaymentArrangement(billingAccount.invoiceGroupId)
                dispatch(userActions.setIsOnPaymentPlan({ billingAccountNumber, isOnPaymentPlan }))
            }
        }
        if (isActive) {
            getPaymentPlan()
        }
    }, [billingAccountNumber, isActive, profile.isCommercialIndustrial])

    if (!billingAccount) {
        return <></>
    }

    const dueDate = billingAccount.dueDate ? new Date(billingAccount.dueDate) : undefined
    const totalAmountDue = billingAccount.amountDue || 0
    const serviceAddresses = profile ? profile.serviceAddresses : Array<UserProfileServiceAddress>()
    const serviceAccounts = profile ? profile.accounts : Array<UserProfileAccount>()
    const serviceAddressForBillingAccount = getBillingAccountServiceAddresses(billingAccount, serviceAddresses, serviceAccounts)

    return (
        <div>
            {showPaymentDelayMessage && <InfoText>Please allow up to 24 hours for recent payments to be reflected in MyAccount.</InfoText>}
            {features.useBillingAccounts ? (
                <BalanceDueDetailsBillingAccount
                    paymentDueDate={dueDate}
                    totalAmountDue={totalAmountDue}
                    showPayBillLink={showPayBillLink}
                    isOnPaymentPlan={billingAccount.isOnPaymentPlan ?? false}
                    billingAccountNumber={billingAccount?.billingAccountNumber}
                    isActive={isActive}
                    paymentExtensionDueDate={billingAccount?.paymentExtension.exisitingPaymentExtensionDueDate}
                    mostRecentInvoiceLink={
                        <MostRecentInvoiceLink key={billingAccount?.billingAccountNumber} billingAccountNumber={billingAccount?.billingAccountNumber ?? ''} />
                    }
                    paymentButton={
                        <PaymentPortalForm
                            email={profile.email}
                            zipCode={serviceAddressForBillingAccount[0].zipCode}
                            submitButtonText={verbiage[Verbiage.paymentButtonText]?.toString()}
                            billingAccountNumber={billingAccount?.billingAccountNumber}
                            isLoggedIn={true}
                            dataTestId="make-payment-button"
                        />
                    }
                />
            ) : (
                <BalanceDueDetailsPartyAccount
                    paymentDueDate={dueDate}
                    totalAmountDue={totalAmountDue}
                    showPayBillLink={showPayBillLink}
                    isOnPaymentPlan={billingAccount.isOnPaymentPlan ?? false}
                    billingAccountNumber={billingAccount?.billingAccountNumber}
                    isActive={isActive}
                    paymentExtensionDueDate={billingAccount?.paymentExtension.exisitingPaymentExtensionDueDate}
                />
            )}
        </div>
    )
}

interface BalanceDueDetailSectionProps {
    readonly accountId: number
    readonly showPayBillLink: boolean
}
