import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import styled from 'styled-components'

import { AccountConnectionStatus } from '@igs-web/common-models/constants/connection-status'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { LinkButton } from '@igs-web/common-ui-components/_atoms/link/link-button'
import { StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { FullWidthMessageBanner } from '@igs-web/common-ui-components/_molecules/banners/message-banner'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeLarge } from '@igs-web/common-ui-components/styles/theme'
import { canRenew } from '@igs-web/common-utilities/services/contract-eligibility-service'
import {
    getBillingAccountServiceAddresses,
    getBillingAccountUrl,
    getServiceAccountUrl,
    getSortedBillingAccountItems,
} from '@igs-web/common-utilities/utilities/billing-account-utilities'

import { RenwalOfferssDashboardWrapper } from 'domain/rate-change/renewal-offers-container'
import * as Routes from 'domain/routes'

import { CommodityDetails } from '../account-details/commodity-details/commodity-details'
import { CustomerInformation } from '../account-details/customer-information/customer-information'
import { CommonReduxState } from '../common-redux'
import { useCompany } from '../company/hooks/useCompany'
import { BalanceDueDetailSection } from '../payment/balance-due-section'
import { PastDueBalanceSection } from '../payment/past-due-section'
import { PaymentExtensionAlert } from '../payment/payment-extension-alert'

import { isAutoRenewRedux } from './auto-renew-redux'
import { GasAccountOptions } from './billing-accounts/account-options-tile'
import { BillingInformation } from './billing-accounts/billing-information'
import { GasPlanAtAGlance } from './billing-accounts/plan-at-a-glance/plan-at-a-glance'
import { RenewalAlert } from './billing-accounts/renewal-alert'
import { SalesAndServices } from './billing-accounts/sales-and-service'
import { useMyAccountDashboard } from './dashboard-context'
import { DashboardGrid, DashboardGridColumnSpan, DashboardGridRowSpan } from './myaccount-components'
import { AddressSelectorBoxListItem, ServiceAddressesSelectorBox } from './service-address-selector-box'
import { useAccountConnectionStatus } from './use-account-connection-status'
import { useVariableRates } from './use-variable-rates'

const MultiAccountHeader = styled(DashboardGridColumnSpan)`
    ${StrongText} {
        font-size: ${fontSizeLarge};
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: ${Spacing.Medium};
    @media (max-width: ${Breakpoint.Tablet}) {
        flex-direction: column;
        padding-block-end: ${Spacing.Medium};
        .btn {
            width: 100%;
        }
    }
`

export const BillingAccountDashboard = () => {
    const { params } = useRouteMatch<RouteParameters>()
    const { features } = useCompany()
    const history = useHistory()
    const { setServiceAddress, serviceAddress, billingAccount, profile, getBillingAddress, getBillingAccountStatus, getServiceAddressStatus } =
        useMyAccountDashboard()
    const { billingAccountNumber, serviceAddressKey } = params
    const dispatch = useDispatch()

    const account = profile.accounts.find(
        a => a.serviceAddressKey === serviceAddress?.serviceAddressKey && billingAccount?.partyAccountIds.includes(a.accountId),
    )
    const hasMultiplebilingAccounts = profile.billingAccounts.length > 1
    const billingInfoRef = React.useRef<HTMLAnchorElement>(null)
    const addressInfoRef = React.useRef<HTMLAnchorElement>(null)

    React.useEffect(() => {
        const scrollToBilling = billingAccountNumber !== billingAccount?.billingAccountNumber
        const scrollToAddress = !scrollToBilling && serviceAddressKey !== serviceAddress?.serviceAddressKey

        setServiceAddress(serviceAddressKey, billingAccountNumber)
        if (scrollToBilling) {
            if (billingInfoRef.current) {
                billingInfoRef.current.scrollIntoView({
                    behavior: 'smooth',
                })
            } else {
                window.scrollTo(0, 0)
            }
        }
        if (scrollToAddress) {
            addressInfoRef.current?.scrollIntoView({
                behavior: 'smooth',
            })
        }
        //Get Variable rates if applicable when these change
    }, [billingAccountNumber, serviceAddressKey, setServiceAddress])

    useVariableRates(account?.accountId)

    const accountConnectionStatus = useAccountConnectionStatus(account?.accountId)

    const partyId = account?.accountId
    const autoRenewInfo = useSelector((store: CommonReduxState) => isAutoRenewRedux.selectors.selectData(store, partyId))
    React.useEffect(() => {
        if (autoRenewInfo === undefined && partyId) {
            dispatch(isAutoRenewRedux.actions.load({ key: partyId, showGlobalLoader: false }))
        }
    }, [partyId])
    const isAutoRenew = !!autoRenewInfo?.isAutoRenew

    if (!account || !billingAccount) {
        return <div>Something went wrong</div>
    }
    const isInRenewalWindow = features.canMyAccountRenew && canRenew(account, features.canRenewAnyStatus)
    const serviceAddressesList: ReadonlyArray<AddressSelectorBoxListItem> = getBillingAccountServiceAddresses(
        billingAccount,
        profile.serviceAddresses,
        profile.accounts,
    )
        .map(address => ({ address, isActive: getServiceAddressStatus(address.serviceAddressKey) === AccountStatus.active }))
        .sort((a, b) => {
            if (a.isActive !== b.isActive) {
                return a.isActive ? -1 : 1
            }
            return a.address.address1 + (a.address.address2 || '') >= b.address.address1 + (b.address.address2 || '') ? 1 : -1
        })

    const billingAccountsList = getSortedBillingAccountItems(profile.billingAccounts, getBillingAccountStatus)

    const handleChangeBillingAccount = selectedBillingAccount => {
        history.push(getBillingAccountUrl(selectedBillingAccount, profile))
    }

    const handleSetServiceAddress = addressKey => {
        history.push(getServiceAccountUrl(billingAccountNumber, addressKey))
    }

    return (
        <>
            {!profile.hasScanaHW && profile.isEligibleForScanaHW && (
                <FullWidthMessageBanner dataTestId="hw-cross-sell-banner" id={'hw-available-message'} isDismissable={false}>
                    <p>
                        SCANA Energy now offers SCANA Home Warranty. Visit the <a href={Routes.HwAccountSummary}>Home Warranty</a> page to learn more about our
                        protection plan options.
                    </p>
                </FullWidthMessageBanner>
            )}
            <DashboardGrid>
                {hasMultiplebilingAccounts && (
                    <MultiAccountHeader>
                        <StrongText>View all your Accounts and Balances in one page:</StrongText>
                        <LinkButton href={Routes.AccountsAndBalances}>View All Accounts</LinkButton>
                    </MultiAccountHeader>
                )}
                {account.isDirectBilledUtilityAccount && (
                    <DashboardGridColumnSpan>
                        <PastDueBalanceSection
                            pendingDisconnect={accountConnectionStatus === AccountConnectionStatus.PendingDisconnect}
                            accountId={account.accountId}
                        />
                    </DashboardGridColumnSpan>
                )}
                {billingAccount?.paymentExtension.exisitingPaymentExtensionDueDate && (
                    <DashboardGridColumnSpan>
                        <PaymentExtensionAlert paymentExtensionDueDate={billingAccount?.paymentExtension.exisitingPaymentExtensionDueDate} />
                    </DashboardGridColumnSpan>
                )}

                {isInRenewalWindow && (
                    <DashboardGridColumnSpan>
                        <RenewalAlert />
                    </DashboardGridColumnSpan>
                )}
                <DashboardGridRowSpan>
                    <a ref={billingInfoRef} />
                    <BillingInformation
                        billingAccountNumber={billingAccount.billingAccountNumber}
                        name={billingAccount.name}
                        addresses={billingAccount.mailDistributions.length ? billingAccount.mailDistributions : [getBillingAddress(billingAccount)]}
                        billingAccounts={billingAccountsList}
                        selectAccount={handleChangeBillingAccount}
                    />
                </DashboardGridRowSpan>
                {account.isDirectBilledUtilityAccount && <BalanceDueDetailSection accountId={account.accountId} showPayBillLink={true} />}

                <GasAccountOptions
                    email={profile.email}
                    billingAccountNumber={billingAccount.billingAccountNumber}
                    invoiceGroupId={billingAccount.invoiceGroupId}
                    isPaperless={profile.isPaperless}
                    zipCode={serviceAddress?.zipCode}
                    hidePaperlessOptions={profile.isCommercialIndustrial}
                />
                {profile.isCommercialIndustrial && (
                    <DashboardGridColumnSpan>
                        <SalesAndServices />
                    </DashboardGridColumnSpan>
                )}
                {serviceAddress && serviceAddressesList.length > 1 && (
                    <DashboardGridColumnSpan>
                        <a ref={addressInfoRef} />
                        <ServiceAddressesSelectorBox address={serviceAddress} addresses={serviceAddressesList} selectAddress={handleSetServiceAddress} />
                    </DashboardGridColumnSpan>
                )}
                {serviceAddress && features.canMyAccountRenew && (
                    <DashboardGridColumnSpan>
                        <RenwalOfferssDashboardWrapper
                            accounts={profile.accounts.filter(a => a.serviceAddressKey === serviceAddress.serviceAddressKey)}
                            zipCode={serviceAddress?.zipCode}
                            displayOnlyHeroProducts={true}
                        />
                    </DashboardGridColumnSpan>
                )}
                <CustomerInformation account={account} serviceAddress={serviceAddress} editLinkUrl={Routes.AccountInformation} />
                <GasPlanAtAGlance
                    account={account}
                    hasSmallCommercialAccount={
                        profile.serviceAddresses?.find(sa => sa.serviceAddressKey === account.serviceAddressKey)?.hasSmallCommercialAccount || false
                    }
                    isCommercialIndustrial={profile.isCommercialIndustrial}
                    isAutoRenew={isAutoRenew}
                />

                {account && (
                    <DashboardGridColumnSpan>
                        <CommodityDetails account={account} />
                    </DashboardGridColumnSpan>
                )}
            </DashboardGrid>
        </>
    )
}

interface RouteParameters {
    readonly billingAccountNumber: string
    readonly serviceAddressKey: string
}
