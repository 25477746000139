import * as React from 'react'
import { useDispatch } from 'react-redux'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { useMyAccountDashboard } from '@igs-web/common-components/domain/myaccount/dashboard-context'
import { userActions } from '@igs-web/common-components/domain/user/user-redux'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { BillingAccountTransactionHistory } from '@igs-web/common-models/models/billing-account-transaction-history'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

import { PastDueBalanceAlert } from './past-due-balance-alert'

export const PastDueBalanceSection = ({ accountId, pendingDisconnect }: Props) => {
    const { features } = useCompany()
    const dispatch = useDispatch()
    const [transactionHistory, setTransactionHistory] = React.useState<BillingAccountTransactionHistory | null>()
    const {
        billingAccount: selectedBillingAccount,
        profile,
        getTransactionHistory,
        getBillingAccountForPartyAccount,
        getBillingAccountStatus,
    } = useMyAccountDashboard()

    const pastDueBalance = transactionHistory?.pastDueBalance
    const billingAccount = selectedBillingAccount && features.useBillingAccounts ? selectedBillingAccount : getBillingAccountForPartyAccount(accountId)
    const { paymentExtension, billingAccountNumber } = billingAccount || {}
    const isActive =
        selectedBillingAccount && features.useBillingAccounts
            ? getBillingAccountStatus(selectedBillingAccount.billingAccountNumber) === AccountStatus.active
            : profile.accounts.find(a => a.accountId === accountId)?.status === AccountStatus.active

    const hasPaymentExtension = !!paymentExtension?.exisitingPaymentExtensionDueDate

    React.useEffect(() => {
        const getPaymentPlan = async () => {
            if (billingAccount && billingAccount.isOnPaymentPlan === undefined && !profile.isCommercialIndustrial) {
                const isOnPaymentPlan = await customerInfoApiClient.getIsOnPaymentArrangement(billingAccount.invoiceGroupId)
                dispatch(userActions.setIsOnPaymentPlan({ billingAccountNumber, isOnPaymentPlan }))
            }
        }

        if (isActive) {
            getPaymentPlan()
            if (!hasPaymentExtension) {
                setTransactionHistory(null)
                getTransactionHistory(billingAccountNumber).then(result => {
                    setTransactionHistory(result)
                })
            }
        }
    }, [billingAccountNumber, isActive, profile.isCommercialIndustrial])

    return (
        <>
            {!!pastDueBalance && !hasPaymentExtension && (
                <PastDueBalanceAlert
                    pendingDisconnect={pendingDisconnect}
                    pastDueBalance={pastDueBalance}
                    billingAccountNumber={billingAccount?.billingAccountNumber}
                />
            )}
        </>
    )
}

interface Props {
    readonly accountId: number
    readonly pendingDisconnect?: boolean
}
